import React, { useEffect, useState, useMemo } from "react";
import { Grid, Box } from "@mui/material";
import PaymentLinkCheckout from "../PaymentLinkCheckout";
import { getCheckoutFireStoreData, loadCheckoutPageData } from "../constants";
import history from "@speed/common/src/components/history";
import CustomNotification from "@speed/common/src/components/Notification/Notification";
import {
  lightningLabel,
  somethingWrong,
} from "@speed/common/src/components/messages";
import { useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { app } from "@speed/common/src/util/firebase";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";
import {
  dummyLightningAddress,
  dummyOnchainAddress,
  dummyPaymentID,
  getValidURL,
  renderUIBasedOnDevice,
} from "@speed/common/src/components/constants";
import classNames from "classnames";
import LatestMobileDesign from "@speed/common/src/components/LatestMobileDesign/LatestMobileDesign";
import WebSkeleton from "@speed/common/src/components/LatestWebDesign/WebSkeleton";
import MobileSkeleton from "@speed/common/src/components/LatestMobileDesign/MobileSkeleton";
import LatestWebDesign from "@speed/common/src/components/LatestWebDesign/LatestWebDesign";
import { PaymentPageContext } from "@speed/common/src/contexts/contexts";

const CheckoutPage = (props) => {
  const [checkoutData, setCheckoutData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [qrCodeLoader, setQrCodeLoader] = useState(false);
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [paymentDetails, setPaymentDetails] = useState(null);
  const checkoutSessionId = props.match && props.match.params.id;
  const urlParams = useParams();
  const [showTransition, setShowTransition] = useState();
  const [showConfirmPreview, setShowConfirmPreview] = useState();
  const [showQRPaymentPreview, serQRPaymentPreview] = useState();
  const [fadeOut, setFadeOut] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isMobileViewRendered, setIsMobileViewRendered] = useState(false);

  const { setPathPageView } = useTrackingCode();
  const pageRoute = history && history.location.pathname;
  const [isShowCashbackRedeemModal, setIsShowCashbackRedeemModal] =
    useState(false);
  const [redirectToSuccessURL, setRedirectToSuccessURL] = useState(false);
  const confirmPayment =
    paymentDetails && paymentDetails.status === "paid" ? true : false;

  const ldClient = useLDClient();
  const { cbRedeemcashbackFe } = useFlags();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        windowHeight: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const ldUser = {
      key: "anonymous-anonymous",
    };
    ldClient.identify(ldUser);
  }, []);

  useEffect(() => {
    if (pageRoute) {
      //To Set the path to the current page in hubspot
      setPathPageView(pageRoute);
    }
  }, [pageRoute]);

  useEffect(() => {
    renderUIBasedOnDevice(setIsMobileViewRendered);
  }, [windowSize]);

  const setNewCheckoutData = async (paymentId, callAPI) => {
    callAPI &&
      (await loadCheckoutPageData(checkoutSessionId).then(
        async (globalStates) => {
          setFadeOut(true);
          setTimeout(() => {
            setFadeOut(false);
            setShowTransition(paymentId);
            setShowConfirmPreview(paymentId);
            serQRPaymentPreview(paymentId);
            setCheckoutData(globalStates);
          }, 800);
        }
      ));
  };

  useEffect(() => {
    if (showConfirmPreview) {
      setTimeout(() => {
        setShowConfirmPreview(false);
      }, 3000);
    }
    if (showQRPaymentPreview) {
      setTimeout(() => {
        serQRPaymentPreview(false);
      }, 4000);
    }
  }, [showConfirmPreview, showQRPaymentPreview]);

  useEffect(() => {
    if (
      checkoutData?.currentAccount?.success_url &&
      paymentDetails?.cashback?.cashback_id &&
      redirectToSuccessURL
    ) {
      window.location.href = getValidURL(
        checkoutData.currentAccount.success_url
      );
    }
  }, [redirectToSuccessURL]);

  const getCheckoutData = async () => {
    setShowTransition();
    setShowConfirmPreview();
    serQRPaymentPreview();
    sessionStorage.clear();

    const authObj = getAuth(app);
    authObj.signOut();
    setLoading(true);
    setCheckoutData(null);
    if (urlParams.id === "success" || urlParams.id === "error") {
      window.location = process.env.REACT_APP_PROMO_WEBSITE;
    }
    loadCheckoutPageData(checkoutSessionId)
      .then(async (globalStates) => {
        const authObj = getAuth(app);
        globalStates.firebaseToken &&
          signInWithCustomToken(authObj, globalStates.firebaseToken)
            .then(() => {
              getCheckoutFireStoreData(
                setNewCheckoutData,
                checkoutSessionId,
                setPaymentDetails,
                globalStates.currentAccount.success_url,
                authObj,
                cbRedeemcashbackFe
              );
            })
            .catch((_e) => {});
        setCheckoutData(globalStates);
        setLoading(false);
      })
      .catch((err) => {
        const errors = err?.response;
        if (errors?.status === 404) {
          history.push({
            pathname: "/pay/error/not-found-link",
            state: {
              from: "checkout",
            },
          });
        } else {
          const errorArr = errors?.data?.errors?.[0];
          if (errorArr?.param === "amount") {
            history.push("/pay/error/amount-exceeded");
          } else {
            history.push("/pay/error/under-maintenance", {
              message:
                errorArr?.type === "account_restriction"
                  ? errorArr?.message
                  : "",
            });
          }
        }
        if (!err.status) {
          setIsToastOpen(true);
          setToastMessage(somethingWrong);
        }
      });
  };

  useEffect(() => {
    getCheckoutData();
  }, []);

  if (checkoutData?.paymentInfo?.status === "paid") {
    history.push({
      pathname: "/pay/success/paid-link",
      state: {
        from: "checkout",
      },
    });
  } else if (checkoutData?.paymentInfo?.status === "deactivated") {
    history.push("/pay/error/deactivated-link");
  }

  useEffect(() => {
    if (cbRedeemcashbackFe && confirmPayment && paymentDetails?.cashback)
      setTimeout(() => {
        setIsShowCashbackRedeemModal(true);
      }, 1000);
  }, [confirmPayment]);

  const applyPreviewBlurFilter = isShowCashbackRedeemModal && {
    sx: {
      filter: "blur(6px)",
    },
  };

  const getAssetDataWithAnyEnabledMethod = (assetData) => {
    const sortedData = assetData?.sort((a, b) => b.is_default - a.is_default);

    return sortedData?.filter((asst) => {
      return asst.payment_method_details?.some(
        (a) => a.is_payment_method_enabled
      );
    });
  };

  const commonPropsInBothView = {
    qrActionBtnWidth: "43% !important",
    visibleInPaymentPage: true,
    headerSectionCustomClass: "",
    TIDVisible: checkoutData?.paymentInfo?.otherInfo,
    onChainAvailable: true,
    lightningLabel: lightningLabel,
    lightningPaymentAddress:
      checkoutData?.paymentInfo?.payment?.payment_method_options?.lightning
        ?.payment_request,
    onChainPaymentAddress:
      checkoutData?.paymentInfo?.payment?.payment_method_options?.on_chain
        ?.payment_request,
    dummyLightningAddress,
    dummyOnchainAddress,
    confirmPageProps: {
      confirmPageLinkID: paymentDetails?.payment_id || dummyPaymentID,
      successMessage: "ThanksPaymentMessage",
      amountTypeTitle: "amount",
      idTitle: "paymentId",
      shouldShowSubTitle: false,
    },
    expireTimeHandler: getCheckoutData,
    paymentPageSettings: checkoutData?.paymentPageSettingsObj,
    checkoutSessionId: checkoutSessionId,
    accountAsset: getAssetDataWithAnyEnabledMethod(
      checkoutData?.paymentInfo?.assets
    ),
  };
  const mobileViewProps = useMemo(() => {
    return commonPropsInBothView;
  }, [checkoutData?.paymentInfo, paymentDetails]);

  const webViewProps = useMemo(() => {
    return {
      boxSx: {
        padding: "0 70px 0 0",
        alignItems: "flex-end",
        borderBottomLeftRadius: 0,
      },
      commonWidth: "442px",
      rightBoxWidth: "0 0 0 70px",
      ...commonPropsInBothView,
    };
  }, [checkoutData?.paymentInfo, paymentDetails]);

  const renderWebVersion = (confirmPayment) => {
    return (
      <Box className="checkout-web-preview" {...applyPreviewBlurFilter}>
        {isLoading ? (
          <WebSkeleton />
        ) : (
          // Updated web design
          <LatestWebDesign
            paymentMessage={checkoutData?.paymentInfo?.success_message}
            webViewProps={webViewProps}
            isMobile={isMobile}
            from="checkout"
            type="web"
            {...checkoutData}
            getCheckoutDataOnExpire={() => getCheckoutData()}
            confirmPayment={confirmPayment}
            paymentDetails={paymentDetails}
            showTransition={showTransition}
            showConfirmPreview={showConfirmPreview}
            showQRPaymentPreview={showQRPaymentPreview}
            setShowTransition={setShowTransition}
            fadeOut={fadeOut}
            isShowCashbackRedeemModal={isShowCashbackRedeemModal}
            setIsShowCashbackRedeemModal={setIsShowCashbackRedeemModal}
            setRedirectToSuccessURL={setRedirectToSuccessURL}
            checkedBillingAddress={
              checkoutData?.paymentInfo?.customer_collections_status
                ?.is_billing_address_enabled
            }
            checkedShippingAddress={
              checkoutData?.paymentInfo?.customer_collections_status
                ?.is_shipping_address_enabled
            }
            checkedEmailAddress={
              checkoutData?.paymentInfo?.customer_collections_status
                ?.is_email_enabled
            }
            checkedPhoneAddress={
              checkoutData?.paymentInfo?.customer_collections_status
                ?.is_phone_enabled
            }
          />
        )}
      </Box>
    );
  };

  const renderMobileVersion = (confirmPayment) => {
    return (
      <Box
        className={classNames("checkout-page-box", "checkout-mobile-preview")}
      >
        <Grid
          className="mobile-view-branding"
          {...applyPreviewBlurFilter}
          sx={{ borderRadius: "0px !important" }}
        >
          {isLoading ? (
            <MobileSkeleton />
          ) : (
            // Updated Mobile design
            <LatestMobileDesign
              paymentMessage={checkoutData?.paymentInfo?.success_message}
              mobileViewProps={mobileViewProps}
              isMobile={isMobile}
              from="checkout"
              type="mobile"
              {...checkoutData}
              height="716px"
              getCheckoutDataOnExpire={() => getCheckoutData()}
              confirmPayment={confirmPayment}
              paymentDetails={paymentDetails}
              showTransition={showTransition}
              showConfirmPreview={showConfirmPreview}
              showQRPaymentPreview={showQRPaymentPreview}
              setShowTransition={setShowTransition}
              fadeOut={fadeOut}
              isShowCashbackRedeemModal={isShowCashbackRedeemModal}
              setIsShowCashbackRedeemModal={setIsShowCashbackRedeemModal}
              setRedirectToSuccessURL={setRedirectToSuccessURL}
              checkedBillingAddress={
                checkoutData?.paymentInfo?.customer_collections_status
                  ?.is_billing_address_enabled
              }
              checkedShippingAddress={
                checkoutData?.paymentInfo?.customer_collections_status
                  ?.is_shipping_address_enabled
              }
              checkedEmailAddress={
                checkoutData?.paymentInfo?.customer_collections_status
                  ?.is_email_enabled
              }
              checkedPhoneAddress={
                checkoutData?.paymentInfo?.customer_collections_status
                  ?.is_phone_enabled
              }
            />
          )}
        </Grid>
      </Box>
    );
  };

  const checkoutPreview = () => {
    const confirmPayment = paymentDetails?.status === "paid";
    return isMobileViewRendered
      ? renderMobileVersion(confirmPayment)
      : renderWebVersion(confirmPayment);
  };

  const contextValues = useMemo(() => {
    return {
      qrCodeLoader,
      setQrCodeLoader,
      setCheckoutData,
      setIsToastOpen,
      setToastMessage,
      getCheckoutData,
      checkoutData,
    };
  }, [qrCodeLoader, checkoutData, isToastOpen, toastMessage]);

  return (
    <PaymentPageContext.Provider value={contextValues}>
      <CustomNotification
        open={isToastOpen}
        onClose={(_event, reason) => {
          if (reason === "clickaway") {
            return;
          }
          setIsToastOpen(false);
        }}
        severity="error"
        message={toastMessage}
        className=""
        autoHideDuration={4000}
      />
      {checkoutData && checkoutData?.paymentInfo?.products ? (
        <PaymentLinkCheckout />
      ) : (
        checkoutPreview()
      )}
    </PaymentPageContext.Provider>
  );
};

export default CheckoutPage;

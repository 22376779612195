import React, { useEffect, useState, useContext } from "react";
import { Box, Grid } from "@mui/material";
import {
  checkLinkOptionsAvailable,
  defaultPaymentMethodForSelectedAsset,
  getBrandingTheme,
  getCommonAllProps,
  getFormattedWalletsData,
  getPreviewTotalAmount,
  handleSetInitialPaymentsObj,
  renderFrequentComponent,
  satsToBtcAmount,
} from "../constants";
import CashbackScanModal from "../CashbackScanModal";
import "../LatestWebDesign/latest-web-design.scss";
import QRCodePreview from "../LatestWebDesign/QRCodePreview";
import LinkConfirmView from "../LinkConfirmView";
import HeaderModal from "./HeaderModal";
import HeaderForMobile from "./HeaderForMobile";
import { wholeQrSkeleton } from "../CommonSkeletonComponent";
import CustomDivider from "../Divider/Divider";
import {
  PaymentPageContext,
  MobilePreviewContext,
} from "../../contexts/contexts";
import PaymentPageFooter from "../PaymentPageFooter";
import PayViaOptions from "../PayViaOptions";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Text from "../Text/Text";
import CustomAccordion from "../Accordion/Accordion";
import { hide, view } from "../../messages";
import Translation from "../Translation";
import EmailInfo from "../EmailInfo";
import RightSideDetailsSection from "../LatestWebDesign/RightSideDetailsSection";
import { getPaymentAddressOfSelectedCurrency } from "@speed/checkout/src/components/constants";

const LatestMobileDesign = (props) => {
  const {
    paymentDetails,
    brandingPageUI,
    isShowCashbackRedeemModal,
    setIsShowCashbackRedeemModal,
    setRedirectToSuccessURL,
    paymentInfo,
    paymentMessage,
    confirmPayment,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    mobileViewProps,
    showPageLoader,
  } = props;

  const [fontFamily, setFontFamily] = useState("system-ui");
  const [textColor, setTextColor] = useState("#fff");
  const [loader, setLoader] = useState(false);
  const [toggleFlag, setToggleFlag] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [expanded, setExpanded] = useState("");
  const [showEditedContactInfo, setShowEditedContactInfo] = useState(false);
  const [dynamicWalletData, setDynamicWalletData] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [activeSection, setActiveSection] = useState("1");
  const [selectedAssetCurrency, setSelectedAssetCurrency] = useState();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [paymentDetailsObj, setPaymentDetailsObj] = useState();
  const [assetAmountLoader, setAssetAmountLoader] = useState(false);
  const [assetQrLoader, setAssetQrLoader] = useState(false);

  const [selectedCurrencyPaymentMethods, setSelectedCurrencyPaymentMethods] =
    useState(defaultPaymentMethodForSelectedAsset);

  // Get context value using consumer
  const paymentPageData = useContext(PaymentPageContext);
  const checkoutSessionId = mobileViewProps?.checkoutSessionId;

  const getWalletsDataFromStrapi = async () => {
    const walletNames = mobileViewProps?.paymentPageSettings?.wallets;
    const formattedWalletsObj = await getFormattedWalletsData(walletNames);
    setDynamicWalletData(formattedWalletsObj);
  };

  useEffect(() => {
    if (mobileViewProps?.visibleInPaymentPage) getWalletsDataFromStrapi();
  }, [mobileViewProps?.paymentPageSettings]);

  const handleScroll = () => {
    if (
      document.documentElement.scrollHeight >
      document.documentElement.clientHeight
    ) {
      const winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (winScroll <= 0) {
        setIsVisible(true);
      } else {
        isVisible && setIsVisible(false);
      }
    }
  };

  useEffect(() => {
    if (mobileViewProps?.visibleInPaymentPage) {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [mobileViewProps?.visibleInPaymentPage]);

  useEffect(() => {
    getBrandingTheme(brandingPageUI, setLoader, setFontFamily, setTextColor);
  }, [brandingPageUI]);

  useEffect(() => {
    brandingPageUI &&
      document
        .querySelector("meta[name='theme-color']")
        .setAttribute("content", additionalProps.previewColor);
  }, [brandingPageUI]);

  useEffect(() => {
    if (
      mobileViewProps?.paymentPageSettings?.display_amount_in_currency?.toLowerCase() ===
      "sats"
    ) {
      setToggleFlag(true);
    }
    if (
      mobileViewProps?.paymentPageSettings?.display_amount_in_currency?.toLowerCase() ===
      "btc"
    ) {
      setToggleFlag(false);
    }
    if (selectedAssetCurrency && selectedAssetCurrency !== "SATS") {
      setToggleFlag(true);
    }
  }, [mobileViewProps?.paymentPageSettings, selectedAssetCurrency]);

  useEffect(() => {
    setSubmitClicked(false);
    if (submitClicked && paymentInfo?.custom_fields?.length > 0) {
      const isAllOptional = paymentInfo.custom_fields.map(
        (item) => item.is_optional
      );
      isAllOptional?.includes(true)
        ? setSubmitClicked(true)
        : setSubmitClicked(false);
    }
  }, [
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    paymentInfo?.custom_fields,
  ]);

  const { additionalProps, isPartialPaymentAvailable } = getCommonAllProps({
    fontFamily,
    textColor,
    toggleFlag,
    loader,
    setToggleFlag,
    visibleInPaymentPage: mobileViewProps?.visibleInPaymentPage,
    displayAmountInCurrency:
      mobileViewProps?.paymentPageSettings?.display_amount_in_currency,
    selectedAssetCurrency,
    paymentDetailsObj,
    ...props,
  });

  const multiAssetProps = {
    selectedAssetCurrency,
    setSelectedAssetCurrency,
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    paymentDetailsObj,
    setPaymentDetailsObj,
    selectedCurrencyPaymentMethods,
    setSelectedCurrencyPaymentMethods,
    assetQrLoader,
    assetAmountLoader,
  };
  const passingProps = {
    ...additionalProps,
    ...props,
    ...multiAssetProps,
    isPartialPaymentAvailable,
    checkoutSessionId,
  };

  const accountProps = {
    stickyHeader: !isVisible,
    setOpenModal,
    openModal,
  };

  // Helper function: Responsible for show/hide details and form in the preview of Right side section
  const {
    contactFormExist,
    customFieldExist,
    isDetailsSectionVisible,
    visibleDetailForm,
    isContactInfoFormVisible,
    isCustomFieldVisible,
    showCustomerCollections,
    checkedLengthKeys,
  } = checkLinkOptionsAvailable({
    paymentInfo,
    checkedBillingAddress,
    checkedEmailAddress,
    checkedPhoneAddress,
    checkedShippingAddress,
    paymentPageData,
    showEditedContactInfo,
    submitClicked,
  });

  useEffect(() => {
    if (
      !paymentInfo?.custom_fields &&
      checkedLengthKeys.every((item) => item === false)
    ) {
      setShowEditedContactInfo(false);
    }
  }, [paymentInfo?.custom_fields, checkedLengthKeys]);

  useEffect(() => {
    if (
      mobileViewProps?.accountAsset?.length &&
      paymentDetails?.status !== "paid"
    ) {
      // set initial payments object having currency and their methods to render tabs when the target currency is null
      const result = handleSetInitialPaymentsObj(
        mobileViewProps,
        paymentInfo,
        props?.from,
        props?.isFromStaticData
      );
      setPaymentDetailsObj(result);
    }
  }, [mobileViewProps?.accountAsset, paymentInfo]);

  useEffect(() => {
    if (props?.from === "checkout") {
      if (selectedPaymentMethod) {
        getPaymentAddressOfSelectedCurrency({
          selectedAssetCurrency,
          selectedPaymentMethod,
          paymentInfo,
          cs_id: props?.id,
          paymentDetailsObj,
          setPaymentDetailsObj,
          setAssetAmountLoader,
          setAssetQrLoader,
        });
      }
    }
  }, [selectedPaymentMethod]);

  const showMainContent = () => {
    return !visibleDetailForm ? (
      <Box>
        <QRCodePreview
          {...passingProps}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          amount={getPreviewTotalAmount({
            data: paymentInfo,
            paymentDetailsObj,
            visibleInPaymentPage: true,
            withoutBTC: true,
          })}
          visibleInPaymentPage={mobileViewProps?.visibleInPaymentPage}
          onChainAvailable={mobileViewProps?.onChainAvailable}
          lightningLabel={mobileViewProps?.lightningLabel}
          dummyLightningAddress={mobileViewProps?.dummyLightningAddress}
          dummyOnchainAddress={mobileViewProps?.dummyOnchainAddress}
          lightningPaymentAddress={
            paymentDetailsObj?.[selectedAssetCurrency]?.[
              selectedPaymentMethod
            ] ||
            mobileViewProps?.lightningPaymentAddress ||
            mobileViewProps?.dummyLightningAddress
          }
          onChainPaymentAddress={
            paymentDetailsObj?.[selectedAssetCurrency]?.[
              selectedPaymentMethod
            ] || mobileViewProps?.onChainPaymentAddress
          }
          confirmPageProps={mobileViewProps?.confirmPageProps}
          isFromInvoice={mobileViewProps?.isFromInvoice}
          boxAmountValue={
            (paymentDetails &&
              `${satsToBtcAmount(paymentDetails.target_amount_paid)} BTC`) ||
            passingProps?.totalAmount
          }
          isDetailsSectionVisible={isDetailsSectionVisible}
          showCustomerCollections={showCustomerCollections}
          setShowEditedContactInfo={setShowEditedContactInfo}
          accountAsset={mobileViewProps?.accountAsset}
          updateSelectedCurrencyState={
            mobileViewProps?.updateSelectedCurrencyState
          }
        />
      </Box>
    ) : (
      <RightSideDetailsSection
        {...props}
        showEditedContactInfo={showEditedContactInfo}
        setShowEditedContactInfo={setShowEditedContactInfo}
        showCustomerCollections={showCustomerCollections}
        isContactInfoFormVisible={isContactInfoFormVisible}
        isCustomFieldVisible={isCustomFieldVisible}
        isWebPreview={false}
        updatePaymentInfoFn={mobileViewProps?.updatePaymentInfoFn}
        visibleInPaymentPage={mobileViewProps?.visibleInPaymentPage}
        checkoutSessionId={checkoutSessionId}
        setSubmitClicked={setSubmitClicked}
        contactFormExist={contactFormExist}
        customFieldExist={customFieldExist}
        setCustomFiledUpdatedFrom={mobileViewProps?.setCustomFiledUpdatedFrom}
      />
    );
  };

  const handleChange = (title) => (_e, newExpanded) => {
    setExpanded(newExpanded ? title : false);
  };

  return (
    <MobilePreviewContext.Provider value={mobileViewProps}>
      <Box
        className="latest-mobile-design"
        display="flex"
        flexDirection="column"
      >
        <Box
          className="mobile-view-header payment-page-preview"
          sx={{
            overflowAnchor: "none",
          }}
          id="container-id"
        >
          {showPageLoader && loader ? (
            renderFrequentComponent("pageLoader", passingProps)
          ) : (
            <>
              {renderFrequentComponent("testLiveMode", passingProps)}
              <Box
                sx={{
                  background: passingProps?.previewColor,
                  padding: "14px",
                }}
                className={`scrollable-header ${!isVisible ? "fixed " : ""}`}
              >
                {renderFrequentComponent("accountHeader", {
                  ...accountProps,
                  ...passingProps,
                  type: "phone",
                  visibleInPaymentPage: mobileViewProps?.visibleInPaymentPage,
                  expireTimeHandler: mobileViewProps?.expireTimeHandler,
                })}
              </Box>
              <Box
                sx={{
                  background: passingProps?.previewColor,
                  padding: "14px",
                  paddingTop: 0,
                  paddingBottom: "24px",
                }}
              >
                <HeaderForMobile
                  {...passingProps}
                  visibleInPaymentPage={mobileViewProps?.visibleInPaymentPage}
                  displayAmountInCurrency={
                    mobileViewProps?.paymentPageSettings
                      ?.display_amount_in_currency
                  }
                  accountAsset={mobileViewProps?.accountAsset}
                />
                {additionalProps?.totalProduct?.length > 0 && (
                  <Box
                    className="product"
                    onClick={() => setOpenModal(true)}
                    sx={{ cursor: "pointer" }}
                    display="flex"
                    alignItems="center"
                  >
                    <Text
                      size={14}
                      className="default-text"
                      variant="subtitle"
                      font="regular"
                    >
                      {`${additionalProps?.totalProduct?.length}${
                        additionalProps?.totalProduct?.length > 1
                          ? " items"
                          : " item"
                      } `}
                    </Text>
                    <KeyboardArrowRightIcon style={{ width: 15, height: 15 }} />
                  </Box>
                )}
              </Box>
              {mobileViewProps?.isShowInvoiceInfoSection && (
                <Box>
                  <CustomAccordion
                    className="invoice-info-accordion"
                    isTitleShown={true}
                    onChange={(title) => handleChange(title)}
                    expanded={expanded}
                    helperText={expanded ? hide : view}
                    expandIcon="rightArrow"
                    accordianPanel={true}
                    title={<Translation value="invoiceInfo" />}
                    customComponent={
                      <EmailInfo
                        paymentInfo={paymentInfo}
                        fontFamily={fontFamily}
                        isGreyText={true}
                        isGetTimezoneFromSystem={
                          mobileViewProps?.isGetTimezoneFromSystem
                        }
                        isDisplayStaticData={
                          mobileViewProps?.isDisplayStaticData
                        }
                        timezone={mobileViewProps?.timezone}
                      />
                    }
                  />
                  <CustomDivider sx={{ margin: "0px 13px" }} />
                </Box>
              )}
              <Box className="new-mobile-qr-section">
                {confirmPayment ? (
                  <Grid className="confirm-payment-wrapper" item lg={12}>
                    <LinkConfirmView
                      activeSection={activeSection}
                      visibleInPaymentPage={
                        mobileViewProps?.visibleInPaymentPage
                      }
                      paymentMessage={paymentMessage}
                      paymentDetails={paymentDetails}
                      paymentInfo={paymentInfo}
                      additionalClass="latest-confirm-preview"
                      confirmPageProps={mobileViewProps?.confirmPageProps}
                      isFromInvoice={mobileViewProps?.isFromInvoice}
                      totalAmount={passingProps?.totalAmount}
                      boxAmountValue={
                        (paymentDetails &&
                          `${satsToBtcAmount(
                            paymentDetails.target_amount_paid
                          )} BTC`) ||
                        passingProps?.totalAmount
                      }
                    />
                  </Grid>
                ) : mobileViewProps?.visibleInPaymentPage &&
                  paymentPageData?.qrCodeLoader ? (
                  wholeQrSkeleton({ size: "325px" })
                ) : (
                  showMainContent()
                )}
              </Box>
            </>
          )}
        </Box>
        <Box marginTop="auto">
          <PayViaOptions
            from=""
            className="pay-via-options-payment"
            paymentPageSettings={mobileViewProps?.paymentPageSettings}
            dynamicWalletData={dynamicWalletData}
          />

          <CustomDivider
            sx={{
              borderColor: "#e4e9ee",
            }}
          />
          <PaymentPageFooter
            fontStyle={additionalProps.fontStyle}
            paymentPageSettings={mobileViewProps?.paymentPageSettings}
          />
        </Box>
      </Box>
      {openModal && (
        <HeaderModal
          openModal={openModal}
          passingProps={passingProps}
          setOpenModal={setOpenModal}
          mobileViewProps={mobileViewProps}
          paymentInfo={paymentInfo}
          fontFamily={fontFamily}
        />
      )}
      {isShowCashbackRedeemModal && (
        <CashbackScanModal
          handleCloseCashbackModal={() => {
            if (setIsShowCashbackRedeemModal)
              setIsShowCashbackRedeemModal(false);
          }}
          cashbackDetails={paymentDetails?.cashback}
          setRedirectToSuccessURL={setRedirectToSuccessURL}
          checkoutSessionId={checkoutSessionId}
        />
      )}
    </MobilePreviewContext.Provider>
  );
};

export default LatestMobileDesign;

import React, { useEffect, useState } from "react";
import CommonSkeleton from "./CommonSkeleton";
import {
  getCurrencyFormat,
  satsToBtcAmount,
  getCurrencyObj,
  showAmount,
} from "./constants";
import { Box } from "@mui/material";
import Text from "./Text/Text";
import classNames from "classnames";
import { CountUp } from "use-count-up";
import PresetAmountPreview from "./LatestWebDesign/PresetAmountPreview";
import AmountOptionsPreview from "./AmountOptionsPreview";
import { isEmpty } from "lodash";
import { anyAmount } from "../messages";

const HeaderAmountSection = (props) => {
  const {
    paymentInfo,
    checkoutLoader,
    showTransition,
    fontStyle,
    toggleFlag,
    totalAmount,
    isZeroAmountLink,
    checkoutSessionId,
    isPartialPaymentAvailable,
    resolution, // New design flag
    visibleInPaymentPage,
    displayAmountInCurrency,
    paymentDetails,
    selectedAssetCurrency = "SATS",
    assetAmountLoader,
    paymentDetailsObj,
    selectedPaymentMethod,
  } = props;

  const [assetAmount, setAssetAmount] = useState();
  const [fiatAmount, setFiatAmount] = useState();
  //states for transition
  const [fiatAmountTransition, setFiatAmountTransition] = useState();
  const [assetAmountTransition, setAssetAmountTransition] = useState();
  const [oldFiatAmount, setOldFiatAmount] = useState();
  const [oldAssetAmount, setOldAssetAmount] = useState();

  const showBtcAmount = !toggleFlag && selectedAssetCurrency === "SATS";
  const exchangeRate = paymentInfo?.payment?.exchange_rate;

  const prevAmount =
    sessionStorage.getItem("cl_pending_target_amount") &&
    JSON.parse(sessionStorage.getItem("cl_pending_target_amount"));

  const handleChangeDataForCountUp = (amount, fiatAmount = null) => {
    setFiatAmountTransition(false);
    setOldFiatAmount(amount);

    amount &&
      sessionStorage.setItem(
        "cl_pending_target_amount",
        JSON.stringify(fiatAmount || amount)
      );
  };

  const handleGetAssetAmount = () => {
    const targetCurrency = paymentInfo?.target_currency;

    let amount = 0;

    if (targetCurrency) {
      amount = exchangeRate * paymentInfo?.amount;
    } else if (!isEmpty(paymentDetailsObj)) {
      amount = paymentDetailsObj?.[selectedAssetCurrency]?.target_amount;
    } else {
      amount = exchangeRate * paymentInfo?.amount;
    }
    return selectedAssetCurrency === "SATS" ? Math.ceil(amount) : amount;
  };

  const handleSetInitialValue = () => {
    let assetValue = handleGetAssetAmount();
    setAssetAmount(assetValue);
    setFiatAmount(paymentInfo?.amount);
  };

  useEffect(() => {
    if (paymentInfo || !isEmpty(paymentDetailsObj)) {
      handleSetInitialValue();
    }
  }, [paymentInfo, paymentDetailsObj, selectedAssetCurrency]);

  useEffect(() => {
    if (visibleInPaymentPage) {
      setFiatAmountTransition(showTransition);
      setAssetAmountTransition(showTransition);
      if (!showTransition) {
        handleChangeDataForCountUp(fiatAmount);
      }

      setTimeout(() => {
        if (showTransition) {
          handleChangeDataForCountUp(prevAmount, fiatAmount);
        }
      }, 2200);

      setTimeout(() => {
        setAssetAmountTransition(false);
        if (paymentInfo) {
          const assetValue = handleGetAssetAmount();
          setOldAssetAmount(assetValue);
          sessionStorage.setItem(
            "cl_pending_amount",
            JSON.stringify(assetValue)
          );
        }
      }, 3500);
    }
  }, [paymentInfo, fiatAmount]);

  useEffect(() => {
    if (visibleInPaymentPage && prevAmount !== oldFiatAmount) {
      setTimeout(() => {
        if (showTransition) {
          setOldFiatAmount(prevAmount);
        }
      }, 2700);
    }
  }, [prevAmount, oldFiatAmount]);

  const excludedAmountType = ["amountOptions", "preset"];

  const commonProps = {
    paymentInfo,
    fontStyle,
    visibleInPaymentPage,
    checkoutSessionId,
    displayAmountInCurrency,
    paymentStatus: paymentDetails?.status,
    selectedAssetCurrency,
    assetAmountLoader,
    paymentDetailsObj,
    selectedPaymentMethod,
  };

  const checkPaymentsAvailable = !paymentInfo?.payments?.length;

  const showMainAmount = assetAmountLoader ? (
    <CommonSkeleton />
  ) : (
    <Box marginTop={props.pageTitle && "12px"} marginBottom="10px">
      <Text
        className="checkout-acc-amount pointer-cursor"
        size={28}
        sx={{
          lineHeight: "normal !important",
          wordWrap: "break-word",
          width: "unset",
          ...fontStyle,
        }}
        font="regular"
        variant="subtitle1"
      >
        {visibleInPaymentPage && oldFiatAmount !== fiatAmount ? (
          <>
            <CountUp
              isCounting
              start={oldFiatAmount}
              end={fiatAmount}
              decimalPlaces={
                fiatAmount - Math.floor(fiatAmount) !== 0
                  ? showAmount({
                      amount: fiatAmount,
                      currency: paymentInfo?.currency?.code,
                      showCommaSeparated: true,
                      multiplier: 1,
                    })
                      ?.toString()
                      ?.split(".")?.[1]?.length
                  : 0
              }
              decimalSeparator="."
              duration={1}
            />{" "}
            {paymentInfo?.currency?.code}
          </>
        ) : (
          <>
            {paymentInfo?.amount === 0 &&
            (paymentInfo?.amountType === "fixed" ||
              paymentInfo?.amountType === "anyAmount")
              ? anyAmount
              : showAmount({
                  amount: fiatAmountTransition ? oldFiatAmount : fiatAmount,
                  currency: paymentInfo?.currency?.code,
                  showCommaSeparated: true,
                  appendCurrency: true,
                  multiplier: 1,
                })}
          </>
        )}
      </Text>
    </Box>
  );

  const renderAmount = () => {
    if (paymentInfo?.amountType === "preset") {
      return (
        <PresetAmountPreview
          {...commonProps}
          totalAmount={totalAmount}
          resolution={resolution}
        />
      );
    } else if (paymentInfo?.amountType === "amountOptions") {
      return <AmountOptionsPreview {...commonProps} />;
    } else {
      return showMainAmount;
    }
  };

  const handleShowAssetAmount = (amount) => {
    const displayAmount = showBtcAmount ? satsToBtcAmount(amount) : amount;
    return displayAmount;
  };

  const renderAmountTypeSection =
    !isPartialPaymentAvailable && checkPaymentsAvailable
      ? renderAmount()
      : showMainAmount;

  const checkForAmountTypeExist = checkPaymentsAvailable
    ? !excludedAmountType.includes(paymentInfo?.amountType)
    : true;

  return (
    <Box className="amount-currency-wrapper">
      {checkoutLoader ? (
        <CommonSkeleton
          className="checkout-acc-amount"
          height={15}
          width={200}
          color="#c4ccd2"
          sx={{
            borderRadius: "6px",
            margin:
              resolution === "web"
                ? "23px 0 !important"
                : "15px 95px 15px 95px !important",
          }}
        />
      ) : (
        <>
          {renderAmountTypeSection}
          {!isZeroAmountLink &&
            (checkForAmountTypeExist || isPartialPaymentAvailable) &&
            exchangeRate && (
              <Text
                className={classNames(
                  "checkout-acc-currency",
                  showTransition &&
                    !assetAmountTransition &&
                    "animate-currency-text"
                )}
                size={14}
                font="regular"
                variant="subtitle1"
                sx={{ lineHeight: "normal !important", ...fontStyle }}
              >
                {getCurrencyFormat({
                  amount: handleShowAssetAmount(
                    assetAmountTransition ? oldAssetAmount : assetAmount
                  ),
                  currency: {
                    code: paymentInfo?.currency?.code,
                    symbol: getCurrencyObj(
                      showBtcAmount ? "BTC" : selectedAssetCurrency
                    )?.symbol,
                  },
                  uuid: showBtcAmount ? "BTC" : selectedAssetCurrency,
                })}
              </Text>
            )}
        </>
      )}
    </Box>
  );
};

export default HeaderAmountSection;

import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { renderFrequentComponent } from "../constants";
import InvoicePageProductsModal from "../InvoicePageProductsModal";

const HeaderModal = (props) => {
  const {
    openModal,
    passingProps,
    setOpenModal,
    mobileViewProps,
    paymentInfo,
    fontFamily,
  } = props;

  useEffect(() => {
    let element = document.getElementsByClassName("latest-mobile-design")?.[0]
      ?.classList;
    if (openModal) {
      element?.add("modal-header-styles");
    } else {
      element?.remove("modal-header-styles");
    }
    return () => {
      element?.remove("modal-header-styles");
    };
  }, [openModal]);

  return openModal ? (
    <Box className="header-modal">
      <Box padding="14px" className="mobile-modal-header">
        {renderFrequentComponent("accountHeader", {
          ...passingProps,
          headerModal: true,
          setOpenModal,
          type: "",
        })}
      </Box>
      <Box padding="0px 14px">
        <InvoicePageProductsModal
          from={mobileViewProps?.from}
          isShowTotal={mobileViewProps?.isShowTotal}
          paymentInfo={paymentInfo}
          fontFamily={fontFamily + " !important"}
          products={paymentInfo?.invoice_line_items || []}
          isShowItemsHeader={true}
          shouldBackgroundBlur={mobileViewProps?.shouldBackgroundBlur}
          getDataFromReducer={mobileViewProps?.getDataFromReducer}
        />
      </Box>
    </Box>
  ) : null;
};

export default HeaderModal;

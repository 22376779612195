import { Drawer, Box } from "@mui/material";
import Translation from "./Translation";
import CloseIcon from "@mui/icons-material/Close";
import CustomDivider from "./Divider/Divider";
import Text from "./Text/Text";
import {
  dateTimeFormat,
  getCurrencyFormat,
  satsToBtcAmount,
  showAmount,
} from "./constants";
import Clipboard from "./Clipboard/Clipboard";
import { isEmpty } from "lodash";

const CheckoutViewCalculationDrawer = ({
  fontFamily,
  openViewCalculationDrawer,
  closeViewCalculationDrawer,
  paymentData,
  paymentDetailsObj,
  selectedAssetCurrency,
}) => {
  const titleFontStyle = { fontFamily, fontWeight: 600 };
  const currencyData = {
    currency: paymentData?.currency,
    amount: showAmount({
      amount: paymentData.amount_pending,
      currency: paymentData?.currency,
    }),
    uuid: selectedAssetCurrency,
  };
  const amountDueInBtc = satsToBtcAmount(paymentData.target_amount_pending);

  // amount calculation for selected currency
  const calculatedMultiCurrencyDueAmount =
    paymentData?.amount_pending *
    paymentDetailsObj?.[selectedAssetCurrency]?.exchange_rate;

  const showMultiCurrencyDueAmount = showAmount({
    amount: calculatedMultiCurrencyDueAmount,
    currency: paymentData?.currency,
    showCommaSeparated: selectedAssetCurrency !== "SATS",
  });

  //when there is multiple asset currency and selected currency is SATS then display due amount in BTC otherwise in selected currency
  const amountDueInMultiCurrency =
    selectedAssetCurrency === "SATS"
      ? `${satsToBtcAmount(showMultiCurrencyDueAmount)} BTC`
      : `${showMultiCurrencyDueAmount} ${selectedAssetCurrency}`;

  const amountPaidData = paymentData.payments.map((paymentObj) => {
    return {
      payment_id: paymentObj.id,
      paidOn: dateTimeFormat(paymentObj.modified),
      paymentValue: (
        <>
          {currencyData.currency.symbol}
          {showAmount({
            amount: paymentObj.amount_paid,
            currency: currencyData.currency.code,
            showCommaSeparated: true,
          })}
        </>
      ),
    };
  });

  const handleCloseDrawer = () => closeViewCalculationDrawer(false);

  const DrawerHeader = () => {
    return (
      <Box className="drawer-header">
        <Text
          className="default-text"
          size={18}
          variant="h3"
          sx={titleFontStyle}
        >
          <Translation value="paymentCalculation" />
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleCloseDrawer}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };

  const drawerBody = () => {
    const paidOnContent = (
      <>
        {amountPaidData.length > 0 &&
          amountPaidData.map((payment) => (
            <Box key={payment.id} className="payment-paid-on-section">
              <Box className="payment-id-box">
                <Box display="flex">
                  <Text
                    className="default-text payment-id-text"
                    sx={titleFontStyle}
                    size={14}
                    font="regular"
                    variant="h3"
                    noWrap
                  >
                    {payment.payment_id}
                  </Text>
                  <Clipboard text={payment.payment_id} />
                </Box>

                <Text
                  className="default-text"
                  sx={{ fontFamily }}
                  size={16}
                  font="regular"
                  variant="h3"
                >
                  {payment.paymentValue}
                </Text>
              </Box>
              <Text
                sx={{ fontFamily, marginBottom: "10px" }}
                size={14}
                className="grey-text"
                font="regular"
                variant="h3"
              >
                {payment.paidOn}
              </Text>
              <CustomDivider />
            </Box>
          ))}
      </>
    );

    const totalPaymentContent = (
      <Box className="total-payment">
        <Text
          className="default-text"
          sx={{ fontFamily }}
          size={16}
          font="regular"
          variant="h3"
        >
          <Translation value="totalAmountPaid" />
        </Text>
        <Text
          className="default-text"
          sx={titleFontStyle}
          size={16}
          variant="h3"
        >
          <>
            {currencyData.currency.symbol}
            {showAmount({
              amount: paymentData.amount_paid,
              currency: currencyData.currency.code,
              showCommaSeparated: true,
            })}
          </>
        </Text>
      </Box>
    );

    const amountDueContent = (
      <Box className="amount-due">
        <Text
          className="default-text"
          sx={{ fontFamily }}
          size={16}
          font="regular"
          variant="h3"
        >
          <Translation value="amountDue" />
        </Text>
        <Box display="grid" justifyItems="end">
          <Text
            className="default-text btc-due-amount"
            sx={titleFontStyle}
            size={16}
            variant="h3"
          >
            {!isEmpty(paymentDetailsObj)
              ? amountDueInMultiCurrency
              : `${amountDueInBtc} BTC`}
          </Text>
          <Text font="regular" className="grey-text" size={14} variant="h3">
            {getCurrencyFormat(currencyData)}
          </Text>
        </Box>
      </Box>
    );

    return (
      <>
        <Box className="paid-amount-detail-box">
          {paidOnContent}
          {totalPaymentContent}
        </Box>
        <CustomDivider />
        <Box className="due-amount-detail-box">{amountDueContent}</Box>
      </>
    );
  };

  return (
    <Drawer
      id="checkout-view-drawer"
      className="checkout-drawer"
      anchor="right"
      open={openViewCalculationDrawer}
      onClose={handleCloseDrawer}
    >
      <Box className="drawer-wrapper" role="presentation">
        <DrawerHeader />
        <CustomDivider />
        <Box className="view-calc-body-content">{drawerBody()}</Box>
      </Box>
    </Drawer>
  );
};

export default CheckoutViewCalculationDrawer;
